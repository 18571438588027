import React from "react";
import {Container} from "react-bootstrap";

import logo from "../images/logo2.png";

const TopContainer = () => (
  <Container className="App-why">
    <img src={logo} className="App-why-logo" alt="Solar Power Toolbox"/>
    <div>
      <h1>Solar Power Toolbox is a small collection of useful calculators to help you design your off-grid solar power system!</h1>
      <br/>
      <div className="App-why-info">
        Click on the calculator to launch it.
      </div>
    </div>
  </Container>
);

export default TopContainer;
