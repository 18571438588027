import React, {useState} from "react";
import {Container, Col, Row} from "react-bootstrap";

import listSites from "./listsites";

import loadingsun from "../images/loadingsun.gif";
import solarpaneldiagram from "../images/solarpaneldiagram.png";
import "./Repertory.css";

const Repertory = () => {
  const [iframeLoading, setIframeLoading] = useState(Array(listSites.length).fill(true));
  const [loadDiagram, setLoadDiagram] = useState(true)
  const onLoad = (idx) => {
    const newIframeLoading = [...iframeLoading];
    newIframeLoading[idx] = false;
    setIframeLoading(newIframeLoading);
  };

  return (
    <Container className="App-repertory">
      <Row>
        <Col className="App-repertory-hardcopy">
          <a href="https://solarpaneldiagram.com">
            <div className="App-repertory-wrapper-diagram" style={{height: "100%"}}>
              <b>Solar Panel Diagram</b>
              <div className="App-repertory-desc App-diagram">Design your own solar panel diagram has never been so easy</div>
              <div style={{width: "100%", height: "auto", padding: "0 10px 10px 10px"}}>
                <img style={{width: "100%", height: "auto"}} src={solarpaneldiagram} alt="Solar panel diagram"/>
              </div>
              <div className={`App-repertory-iframe-wrapper-loading${loadDiagram ? " loading" : ""}`}>
                <img src={loadingsun} alt="Loading" onLoad={() => {setLoadDiagram(false)}} />
                <span>Loading...</span>
              </div>
            </div>
          </a>
        </Col>
      </Row>

      <Row>
        {
          listSites.map((site, i) => {
            return (
              <Col key={i} sm={6}>
                <a href={site.link}>
                  <div className="App-repertory-wrapper">
                    <b>{site.name}</b>
                    <div className="App-repertory-desc">{site.desc}</div>
                    <iframe
                      className="App-repertory-iframe"
                      src={site.link}
                      title={site.name}
                      onLoad={() => onLoad(i)}
                      scrolling="no"
                    />
                    <div className={`App-repertory-iframe-wrapper-loading${loadDiagram ? " loading" : ""}`}>
                      <img src={loadingsun} alt="Loading" onLoad={() => {setLoadDiagram(false)}} />
                      <span>Loading...</span>
                    </div>
                  </div>
                </a>
              </Col>
            );
          })
        }
      </Row>
      <Row>

      </Row>
    </Container>
  )
};

export default Repertory;
