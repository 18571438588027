import React, {useEffect, useState} from "react";
import {Container, Col, Row} from "react-bootstrap";

import listSites from "./listsites";

import loadingsun from "../images/loadingsun.gif";
import solarpaneldiagramSrc from "../images/apps_img/solarpaneldiagram.png";
import solarpaneldiagram from "../images/solarpaneldiagram.png";
import "./RepertoryMobile.css";

const RepertoryMobile = () => {
  const [loadDiagram, setLoadDiagram] = useState(true);
  const [isLoadingImg, setIsLoadingImg] = useState(true);
  const [listImg, setListImg] = useState([]);

  useEffect(() => {
    const preLoad = async () => {
      const listImgs = [];
      const listUrl = listSites.reduce((acc, site) => {
        const pathImg = site.link
          .replace("https://", "")
          .replace("www.", "")
          .replace(".com", "");
        acc.push(pathImg);
        return acc;
      }, []);

      while(listUrl.length) {
        const picture = await import(`../images/apps_img/${listUrl.shift()}.png`);
        listImgs.push(picture);
      }
      setListImg(listImgs);
      setIsLoadingImg(false);
    };

    preLoad();

  }, []);

  return (
    <Container className="App-repertory-mobile">
      <Row>
        <Col className="App-repertory-mobile-hardcopy">
          <a href="https://solarpaneldiagram.com">
            <div className="App-repertory-mobile-wrapper-diagram" style={{height: "100%"}}>
              <b><img alt="Solarpaneldiagram.com" src={solarpaneldiagramSrc} style={{width: "60%"}}/></b>
              <div className="App-repertory-mobile-desc App-diagram">Design your own solar panel diagram has never been so easy</div>
              <div style={{width: "100%", height: "auto", padding: "0 10px 10px 10px"}}>
                <img style={{width: "100%", height: "auto"}} src={solarpaneldiagram} alt="Solar panel diagram"/>
              </div>
              <div className={`App-repertory-mobile-iframe-wrapper-loading${loadDiagram ? " loading" : ""}`}>
                <img src={loadingsun} alt="Loading" onLoad={() => {setLoadDiagram(false)}} />
                <span>Loading...</span>
              </div>
            </div>
          </a>
        </Col>
      </Row>

      <Row>
        {
          listSites.map((site, i) => {
            return (
              <Col key={site.link} xs={6} sm={6}>
                <a href={site.link}>
                  <div className="App-repertory-mobile-wrapper">
                    <b><img alt={site.link} className="App-repertory-mobile-logo" src={listImg[i]?.default} /></b>
                    <div className="App-repertory-mobile-desc">{site.desc}</div>
                    {/*<iframe*/}
                    {/*  className="App-repertory-mobile-iframe"*/}
                    {/*  src={site.link}*/}
                    {/*  title={site.name}*/}
                    {/*  onLoad={() => onLoad(i)}*/}
                    {/*  scrolling="no"*/}
                    {/*/>*/}

                    <div className={`App-repertory-mobile-iframe-wrapper-loading${loadDiagram || isLoadingImg ? " loading" : ""}`}>
                      <img src={loadingsun} alt="Loading" onLoad={() => {setLoadDiagram(false)}} />
                      <span>Loading...</span>
                    </div>
                  </div>
                </a>
              </Col>
            );
          })
        }
      </Row>
      <Row>

      </Row>
    </Container>
  )
};

export default RepertoryMobile;
