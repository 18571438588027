const listSites = [
  {
    name: "Solar Charge Controller Calculator",
    link: "https://solarchargecontrollercalculator.com",
    desc: "Match your solar panels setup with a compatible charge controller"
  },
  {
    name: "Solar Wire Size Calculator",
    link: "https://solarwiresizecalculator.com",
    desc: "Calculate your wire gauge for you solar power system"
  },
  {
    name: "Solar Power Sizing Calculator",
    link: "https://www.solarpowersizingcalculator.com",
    desc: "Calculate your solar power size. Battery, Solar panels, etc..."
  },
  {
    name: "Solar Battery Bank Calculator",
    link: "https://www.solarbatterybankcalculator.com",
    desc: "Find which battery fit your needs"
  },
  {
    name: "Solar Inverter Calculator",
    link: "https://www.solarinvertercalculator.com",
    desc: "Find the right inverter size"
  },
];

export default listSites;
