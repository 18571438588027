import React, {Fragment} from "react";
import {Button, Col, Row} from "react-bootstrap";

import "./AmazonLink.css";

import { amazonLinks } from "../global";

const AmazonLink = () => {
  return (
    <Row className="g-1 App-amazon-link">
      <Col>
        <div className="amazon-link">
          <h4>Our recommanded products:</h4>
          <div className="line-results-amazon">
            {
              Object.keys(amazonLinks).map((type, i) => (
                <Fragment key={i}>
                  {i > 0 && <div className="App-amazon-separator" />}
                  <h5>{type.split("_").join(" ")}</h5>
                  <Row style={{alignItems: "center"}}>
                    <Col><b className="title-link">{amazonLinks[type].name}</b></Col>
                    <Col style={{textAlign: "center"}}><img alt={amazonLinks[type].name} src={amazonLinks[type].img} /></Col>
                  </Row>
                  <Row style={{alignItems: "center"}}>
                    <Col>{amazonLinks[type].desc}</Col>
                    <Col style={{textAlign: "center"}}>
                      <a href={amazonLinks[type].link}>
                        <Button variant="warning">Click here to see it on amazon</Button>
                      </a>
                    </Col>
                  </Row>

                </Fragment>
              ))
            }
          </div>

        </div>
      </Col>
    </Row>
  )
};

export default AmazonLink;
