import React, {useEffect, useState} from "react";
import isMobile from "ismobilejs";

import Disclaimer from "./Disclaimer";
import Repertory from "./Repertory";
import RepertoryMobile from "./Repertory/RepertoryMobile"
import AmazonLink from "./AmazonLink";
import TopContainer from "./TopContainer";

import {useWindowSize} from "./tools";

import "./App.css";

const App = () => {
  const [isMobileActive, setIsMobile] = useState(isMobile(window.navigator).any);
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsMobile(isMobile(window.navigator).any);
  }, [windowSize]);

  return (
    <div className="App">
      <TopContainer />

      {
        isMobileActive
          ? <RepertoryMobile/>
          : <Repertory />
      }

      <AmazonLink />

      <Disclaimer />
    </div>
  );
}

export default App;
